import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import { Typography } from "@material-ui/core";

const categories = ["Em", "So", "DE", "SpP", "SpV"];

export default class MainscaleChooseCategoryCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            chosenCategories: [1, 2, 3, 4, 5],
            error: false
        };

        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCategoryChange(event) {
        var currentCats = this.state.chosenCategories;
        var id = parseInt(event.target.id);

        if (event.target.checked) {
            if (!currentCats.includes(id)) {
                currentCats.push(id);
                this.setState({ chosenCategories: currentCats });
            }
        } else {
            if (currentCats.includes(id)) {
                var index = currentCats.indexOf(id);
                currentCats.splice(index, 1);

                this.setState({ chosenCategories: currentCats });
            }
        }
    }

    getCurrentValue(cat) {
        if (this.state.chosenCategories.includes(cat)) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit() {
        if (this.state.chosenCategories.length === 0) {
            this.setState({ error: true });
        } else {
            this.setState({ error: false });

            var submitCategories = [];

            this.state.chosenCategories.sort();

            for (var i = 0; i < this.state.chosenCategories.length; i++) {
                submitCategories.push(categories[this.state.chosenCategories[i] - 1]);
            }


            this.props.onSubmit(submitCategories);
        }
    }

    render() {
        console.log(JSON.stringify(this.state));

        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Dimensionen
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Wählen Sie die zu erfassenden Dimensionen aus:
                    </Typography>
                    <FormControl component="fieldset" error={this.state.error}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={this.getCurrentValue(1)} onChange={this.handleCategoryChange} name="Em" id="1" />}
                                label="Emotionale Entwicklung"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.getCurrentValue(2)} onChange={this.handleCategoryChange} name="So" id="2" />}
                                label="Soziale Entwicklung"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.getCurrentValue(3)} onChange={this.handleCategoryChange} name="DE" id="3" />}
                                label="Denkentwicklung"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.getCurrentValue(4)} onChange={this.handleCategoryChange} name="SpP" id="4" />}
                                label="expr. Sprachentwicklung"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.getCurrentValue(5)} onChange={this.handleCategoryChange} name="SpV" id="5" />}
                                label="rezep. Sprachentwicklung"
                            />
                        </FormGroup>
                        {this.state.error && <FormHelperText>Sie müssen mindestens eine Dimension auswählen!</FormHelperText>}
                    </FormControl>
                    <Box mt={2}>
                        <Typography style={{ fontWeight: 600 }}>HINWEIS: Sie können Ihre Auswahl später nicht mehr korrigieren!</Typography>
                    </Box>
                </CardContent>
                <CardActions>
                    <Box>
                        <Button variant="contained" onClick={this.handleSubmit}>Einschätzung starten</Button>
                    </Box>
                </CardActions>
            </Card>
        );
    }
}