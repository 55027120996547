import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import SkippedItemsList from './SkippedItemsList';

export default class SkippedItemsCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }



    render() {
        return (
            <Card>
                <CardContent>
                    <SkippedItemsList {...this.props} />
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="primary" onClick={this.props.backToItemWorkflow}>Zurück zur regulären Item-Erfassung</Button>
                </CardActions>
            </Card>
        );
    }
}