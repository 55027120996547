const YELLOW = "#FFFF00";
const GREEN = "#00FF00";
const RED = "#FF0000";
const BROWN = "#964B00";
const LILA = "#800080";
const BLUE = "#1E90FF";
const WHITE = "#ffffff";
const GRAY = "#808080";
const LIGHT_GRAY = "#D3D3D3";

export default class MainscaleCanvas {
    constructor(canvas, items, questionaireType, forExport) {
        this.ctx = canvas.getContext('2d');
        this.forExport = forExport;

        this.items = items;
        this.questionaireType = questionaireType;

        this.draw = this.draw.bind(this);
    }

    draw() {
        const items = this.items;
        const ctx = this.ctx;
        const categories = this.getItemCategories(items);
        const ageRanges = this.getAgeRanges(items);

        ctx.clearRect(0, 0, this.calculateCanvasWidth, this.calculateCanvasHeight());
        ctx.fillStyle = WHITE;
        ctx.fillRect(0, 0, this.calculateCanvasWidth(), this.calculateCanvasHeight());


        this.drawAgeRanges(ctx, ageRanges);
        var i = 0;
        categories.forEach(element => {
            this.drawCategoryBorders(ctx, this.getItemsByCategory(items, element), this.getAgeRanges(items), i);
            this.drawCategory(ctx, items, this.getItemsByCategory(items, element), this.getAgeRanges(items), i);

            i++;
        });

        this.drawLegend(ctx);
    }

    getItemCategories(items) {
        var categories = [];
        items.forEach(element => {
            if (!categories.includes(element.itemCategory.categoryAbbrv)) {
                categories.push(element.itemCategory.categoryAbbrv);
            }
        });

        return categories;
    }

    getItemsByCategory(items, categoryAbbrv) {
        var categoryItems = [];
        items.forEach(element => {
            if (element.itemCategory.categoryAbbrv === categoryAbbrv) {
                categoryItems.push(element);
            }
        });
        return categoryItems;
    }

    getAgeRanges(items) {
        var ageRanges = [];
        items.forEach(element => {
            let found = false;
            ageRanges.forEach(ageRange => {
                if (ageRange[0] === element.itemAge[0] && ageRange[1] === element.itemAge[1]) {
                    found = true;
                }
            });
            if (!found) {
                ageRanges.push(element.itemAge);
            }
        });

        //sort array by the age range beginning
        //a and b are two items from the age range array
        ageRanges.sort((a, b) => {
            if (a[0] < b[0]) {
                return -1;
            }
            if (a[0] > b[0]) {
                return 1;
            }
            return 0;
        });

        return ageRanges;
    }

    getItemsByAgeRange(items, ageRange) {
        var ageItems = [];
        items.forEach(element => {
            if (element.itemAge[0] === ageRange[0] && element.itemAge[1] === ageRange[1]) {
                ageItems.push(element);
            }
        });
        return ageItems;
    }

    drawLegend(ctx) {
        let canvasHeight = this.calculateCanvasHeight();
        let posy = canvasHeight - this.getLegendHeight() + (20 * this.getDevicePixelRatio());


        ctx.fillStyle = "#000000";
        ctx.font = this.getHeadlineFont();
        ctx.textAlign = "left";
        ctx.textBaseline = "top";

        ctx.fillText("Legende: ", this.getYWidth(), posy);
        ctx.font = this.getStandardFont();

        posy = posy + this.getHeadlineFontHeight() + (6 * this.getDevicePixelRatio());
        ctx.fillText("1 - stimmt meistens", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

        ctx.fillStyle = YELLOW;
        ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
        ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());


        if (this.questionaireType === "2") {
            ctx.fillStyle = "#000000";
            posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
            ctx.fillText("2b - stimmt manchmal (keine Situation erfasst/quantitative Auswertung)", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

            ctx.fillStyle = BROWN;
            ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
            ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
        } else {

            ctx.fillStyle = "#000000";
            posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
            ctx.fillText("2a - stimmt manchmal (gute Situationen)", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

            ctx.fillStyle = GREEN;
            ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
            ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());

            ctx.fillStyle = "#000000";
            posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
            ctx.fillText("2b - stimmt manchmal (neutrale Situationen)", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

            ctx.fillStyle = LILA;
            ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
            ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());

            ctx.fillStyle = "#000000";
            posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
            ctx.fillText("2c - stimmt manchmal (schlechte Situationen)", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

            ctx.fillStyle = BLUE;
            ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
            ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());

        }

        ctx.fillStyle = "#000000";
        posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
        ctx.fillText("3 - stimmt nie", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

        ctx.fillStyle = WHITE;
        ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
        ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
    }

    drawAgeRanges(ctx, ageRanges) {
        let canvasHeight = this.calculateCanvasHeight() - this.getLegendHeight();
        let posY = canvasHeight;

        ctx.fillStyle = "#000000";
        ctx.font = this.getHeadlineFont();
        ctx.textAlign = "right";
        ctx.textBaseline = "top";

        const textPosX = this.getYWidth() - (10 * this.getDevicePixelRatio());
        const linePosX = this.getYWidth();

        //draw axis line
        ctx.beginPath();
        ctx.moveTo(linePosX, posY);
        ctx.lineTo(linePosX, this.getTopBorder());
        ctx.lineWidth = this.getLineWidth();
        ctx.stroke();

        //draw age range texts
        ctx.font = this.getHeadlineFont();

        posY = posY - this.getXHeight();

        var ages = []
        ageRanges.forEach(element => {
            if (!ages.includes(element[0])) {
                ages.push(element[0])
            }
            if (!ages.includes(element[1])) {
                ages.push(element[1])
            }
        });

        ages.forEach(age => {
            //draw ticks
            ctx.beginPath();
            ctx.moveTo(linePosX, posY);
            ctx.lineTo(textPosX, posY);
            ctx.lineWidth = this.getLineWidth();
            ctx.stroke();

            ctx.textBaseline = "middle";

            if (age > 36) {
                ctx.fillText((age / 12) + " J ", textPosX, posY);
            } else {
                ctx.fillText(age + " M ", textPosX, posY);
            }
            posY = posY - this.getAgeRangeHeight();
        });

        //draw top tick
        ctx.beginPath();
        ctx.moveTo(linePosX, posY);
        ctx.lineTo(textPosX, posY);
        ctx.lineWidth = this.getLineWidth();
        ctx.stroke();

    }

    drawCategory(ctx, allItems, items, canvasAgeRanges, categoryPosition) {
        let posX = this.getYWidth() + this.getCategoryWidth() * categoryPosition;

        //start from the bottom
        let posY = this.calculateCanvasHeight(canvasAgeRanges.length) - this.getXHeight() - this.getLegendHeight();


        canvasAgeRanges.forEach(element => {
            var ageRangeItems = this.getItemsByAgeRange(items, element);

            var rowHeight, columnWidth;
            //check if we need to draw one or two columns
            if (ageRangeItems.length < 3) {
                //one column layout
                rowHeight = this.getAgeRangeHeight() / ageRangeItems.length;
                columnWidth = this.getCategoryWidth();

                for (let i = 0; i < ageRangeItems.length; i++) {

                    ctx.fillStyle = this.getItemColor(ageRangeItems[i], allItems);
                    ctx.fillRect(posX, posY - (i * rowHeight) - rowHeight, columnWidth, rowHeight);

                    if (ageRangeItems[i].itemType === "f") {
                        if (ageRangeItems[i].itemValue === "1" || ageRangeItems[i].itemValue === "2a" || ageRangeItems[i].itemValue === "2b" || ageRangeItems[i].itemValue === "2c") {
                            ctx.fillStyle = RED;
                            ctx.fillRect(posX, posY - (i * rowHeight) - rowHeight, (columnWidth / 5), rowHeight);
                        }
                    }

                    ctx.strokeRect(posX, posY - (i * rowHeight) - rowHeight, columnWidth, rowHeight);
                    this.drawItemText(ctx, ageRangeItems[i], posX + (columnWidth / 2), posY - (i * rowHeight) - (rowHeight / 2));

                }
            } else {
                //two column layout
                rowHeight = this.getAgeRangeHeight() / Math.ceil(ageRangeItems.length / 2);
                columnWidth = this.getCategoryWidth() / 2;

                var a = 0;
                for (let i = 0; i < ageRangeItems.length; i = i + 2) {

                    //draw left
                    ctx.fillStyle = this.getItemColor(ageRangeItems[i], allItems);
                    ctx.fillRect(posX, posY - (a * rowHeight) - rowHeight, columnWidth, rowHeight);

                    if (ageRangeItems[i].itemType === "f") {
                        if (ageRangeItems[i].itemValue === "1" || ageRangeItems[i].itemValue === "2a" || ageRangeItems[i].itemValue === "2b" || ageRangeItems[i].itemValue === "2c") {
                            ctx.fillStyle = RED;
                            ctx.fillRect(posX, posY - (a * rowHeight) - rowHeight, (columnWidth / 5), rowHeight);
                        }
                    }

                    ctx.strokeRect(posX, posY - (a * rowHeight) - rowHeight, columnWidth, rowHeight);
                    this.drawItemText(ctx, ageRangeItems[i], posX + (columnWidth / 2), posY - (a * rowHeight) - (rowHeight / 2));


                    //draw right
                    if (ageRangeItems.length > (i + 1)) {
                        ctx.fillStyle = this.getItemColor(ageRangeItems[i + 1], allItems);
                    } else {
                        ctx.fillStyle = GRAY;
                    }

                    ctx.fillRect(posX + columnWidth, posY - (a * rowHeight) - rowHeight, columnWidth, rowHeight);

                    if (ageRangeItems.length > (i + 1)) {
                        if (ageRangeItems[i + 1].itemType === "f") {
                            if (ageRangeItems[i + 1].itemValue === "1" || ageRangeItems[i + 1].itemValue === "2a" || ageRangeItems[i + 1].itemValue === "2b" || ageRangeItems[i + 1].itemValue === "2c") {
                                ctx.fillStyle = RED;
                                ctx.fillRect(posX + columnWidth, posY - (a * rowHeight) - rowHeight, (columnWidth / 5), rowHeight);
                            }
                        }

                        this.drawItemText(ctx, ageRangeItems[i + 1], posX + columnWidth + (columnWidth / 2), posY - (a * rowHeight) - (rowHeight / 2));
                    }

                    ctx.strokeRect(posX + columnWidth, posY - (a * rowHeight) - rowHeight, columnWidth, rowHeight);

                    a++;
                }
            }
            posY = posY - this.getAgeRangeHeight();
        });
    }

    drawItemText(ctx, item, posX, posY) {
        var text = item.itemCategory.itemCategoryId;
        if (item.itemType === "f") {
            text = text + "*";
        }
        if (item.itemType === "i") {
            text = text + "i";
        }
        ctx.font = this.getStandardFont();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = '#000000';
        ctx.fillText(text, posX, posY);
    }

    drawCategoryBorders(ctx, items, canvasAgeRanges, categoryPosition) {
        let posX = this.getYWidth() + this.getCategoryWidth() * categoryPosition;
        let posY = this.getTopBorder();

        canvasAgeRanges.forEach(element => {

            ctx.fillStyle = GRAY;
            ctx.fillRect(posX, posY, this.getCategoryWidth(), this.getAgeRangeHeight());
            ctx.strokeRect(posX, posY, this.getCategoryWidth(), this.getAgeRangeHeight());

            posY = posY + this.getAgeRangeHeight();
        });

        //draw right tick
        ctx.beginPath();
        ctx.moveTo(posX + this.getCategoryWidth(), posY);
        ctx.lineTo(posX + this.getCategoryWidth(), posY + this.getXHeight());
        ctx.lineWidth = this.getLineWidth();
        ctx.stroke();

        ctx.font = this.getHeadlineFont();
        ctx.textAlign = "center";
        ctx.textBaseline = "top";

        let textPosX = posX + (this.getCategoryWidth() / 2)

        let textPosY = posY + (5 * this.getDevicePixelRatio());
        ctx.fillStyle = '#000000';
        ctx.fillText(items[0].itemCategory.categoryAbbrv, textPosX, textPosY);
    }

    //returns height of X-Axis
    getXHeight() {
        return 20 * this.getDevicePixelRatio();
    }

    //returns width of Y-Axis
    getYWidth() {
        return 60 * this.getDevicePixelRatio();
    }

    getAgeRangeHeight() {
        return 30 * this.getDevicePixelRatio();
    }

    getCategoryWidth() {
        return 80 * this.getDevicePixelRatio();
    }

    getDevicePixelRatio() {
        if (!this.forExport) {
            return window.devicePixelRatio;
        } else {
            return 1.2;
        }
    }

    getHeadlineFont() {
        let size = this.getHeadlineFontHeight();
        return size + "px Calibri";
    }

    getHeadlineFontHeight() {
        return this.getDevicePixelRatio() * 12;
    }

    getStandardFont() {
        let size = this.getStandardFontHeight()
        return size + "px Calibri";
    }

    getStandardFontHeight() {
        return this.getDevicePixelRatio() * 10;
    }

    getLineWidth() {
        return 1 * this.getDevicePixelRatio();
    }

    getTopBorder() {
        return 20 * this.getDevicePixelRatio();
    }

    getLegendHeight() {
        return (34 * this.getDevicePixelRatio()) + this.getHeadlineFontHeight() + (this.getStandardFontHeight() * 5);
    }

    getLegendWidth() {
        return 350 * this.getDevicePixelRatio();
    }

    calculateCanvasHeight() {
        const ageRangesCount = this.getAgeRanges(this.items).length;
        return (ageRangesCount * this.getAgeRangeHeight()) + this.getXHeight() + this.getTopBorder() + this.getLegendHeight();
    }

    calculateCanvasWidth() {
        const categoryCount = this.getItemCategories(this.items).length;
        const canvasWidth = (categoryCount * this.getCategoryWidth()) + this.getYWidth();
        if (canvasWidth > this.getLegendWidth()) {
            return canvasWidth;
        } else {
            return this.getLegendWidth();
        }
    }

    getItemColor(item, items) {
        if (item.itemValue === "1") {
            return YELLOW;
        }
        if (item.itemValue === "2a") {
            return GREEN;
        }
        if (item.itemValue === "2b") {
            //quantative
            if (this.questionaireType === "2") {
                return BROWN;
            } else {
                return LILA;
            }
        }
        if (item.itemValue === "2c") {
            return BLUE;
        }
        if (item.itemValue === "3") {
            if (item.itemType === "f" || item.itemType === "i") {
                return this.getItemColorFromSuccessor(item, items);
            } else {
                return WHITE;
            }
        }
        return LIGHT_GRAY;
    }

    getItemColorFromSuccessor(item, items) {
        let color = WHITE;

        items.forEach(successor => {
            if (successor.itemId === item.itemSuccessor) {
                if (successor.itemValue === "1" || successor.itemValue === "2a" || successor.itemValue === "2b" || successor.itemValue === "2c") {
                    color = YELLOW;
                } else {
                    color = this.getItemColorFromSuccessor(successor, items);
                }
            }
        });

        return color;
    }

}