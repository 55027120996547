import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
import React from 'react';
import { Redirect } from 'react-router';
import SupplementscalePdfExporter from '../exporter/SupplementscalePdfExporter';
import SupplementscaleCanvasCard from './SupplementscaleCanvasCard';
import PersonCard from './PersonCard';
import PersonFormCard from './PersonFormCard';
import SkippedItemsCard from './SkippedItemsCard';
import SupplementscaleItem from './SupplementscaleItem';
import QualitativeQuantitativeChoiceCard from './QualitativeQuantitativeChoiceCard';

export default class SupplementscaleWorkflow extends React.Component {
    constructor(props) {
        super(props);

        //this.initialize(true);


        let stateObj = {
            items: null,
            currentItem: null,
            supplementData: null,
            workflowStep: 1,
            skippedItems: [],
            maxItemIndex: 0,
            questionaireType: null
        };

        if (props.items) {
            stateObj.items = props.items;
            stateObj.currentItem = props.items[0];
            stateObj.supplementData = props.personData;
            stateObj.workflowStep = 2;
        }

        this.state = stateObj;

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
        this.disableBack = this.disableBack.bind(this);
        this.disableNext = this.disableNext.bind(this);
        this.handlesupplementData = this.handlesupplementData.bind(this);
        this.handleItemValueChange = this.handleItemValueChange.bind(this);
        this.navigateToSkippedItemsList = this.navigateToSkippedItemsList.bind(this);
        this.handleSkippedItem = this.handleSkippedItem.bind(this);
        this.handleSkippedItemValueChange = this.handleSkippedItemValueChange.bind(this);
        this.backToItemWorkflow = this.backToItemWorkflow.bind(this);
        this.handlePdfDownload = this.handlePdfDownload.bind(this);
        this.handleQuestionaireTypeChange = this.handleQuestionaireTypeChange.bind(this);
        this.disableFinish = this.disableFinish.bind(this);

        if (props.setDownloadFn) {
            props.setDownloadFn(this.handlePdfDownload);
        }
    }

    componentDidMount() {
        if (this.state.workflowStep === 1) {
            const postData = {
                token: this.props.token,
                productId: 2
            }

            Axios.post("/api/getItems", postData).then((response) => {
                this.setState({
                    items: response.data,
                    currentItem: response.data[0]
                });
                console.log("AXIOS RESPONSE:");
                console.log(response.data);
                console.log(response.data[0]);
            });
        }
    }

    handleBack() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });
        this.setState({ currentItem: this.state.items[currentItemIndex - 1] });
    }


    handleNext() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });

        var nextItemIndex = currentItemIndex + 1;

        this.setState({
            currentItem: this.state.items[nextItemIndex],
            maxItemIndex: nextItemIndex
        });
    }

    handleFinish() {
        this.setState({ workflowStep: 4 });
    }

    disableFinish() {
        console.log("Disable Finish!!!");
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });
        console.log(this.state.items.length);
        console.log(currentItemIndex);
        if (currentItemIndex === this.state.items.length - 1) {
            return false;
        } else {
            return true;
        }
    }

    disableBack() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });

        if ((this.state.maxItemIndex - currentItemIndex) > 4) {
            return true;
        }
        if (currentItemIndex === 0) {
            return true;
        } else {
            return false;
        }
    }

    disableNext() {
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = this.state.items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });



        if (currentItemIndex < (this.state.items.length - 1)) {
            if (!this.state.items[currentItemIndex].itemValue.value) {
                return true;
            }

            if (this.state.questionaireType === "1") {
                if (this.state.items[currentItemIndex].itemValue.value === "1" || this.state.items[currentItemIndex].itemValue.value === "2") {
                    if (this.state.items[currentItemIndex].itemValue.situation && this.state.items[currentItemIndex].itemValue.intensity) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }

            if (this.state.questionaireType === "2") {
                if (this.state.items[currentItemIndex].itemValue.value === "1" || this.state.items[currentItemIndex].itemValue.value === "2") {
                    if (this.state.items[currentItemIndex].itemValue.intensity) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }

            return false;
        } else {
            return true;
        }
    }

    handlesupplementData(data) {
        this.setState({
            supplementData: data,
            workflowStep: 2
        });
    }

    navigateToSkippedItemsList() {
        this.setState({
            workflowStep: 99
        });
    }

    handleSkippedItem(item) {
        var skippedItems = this.state.skippedItems;
        skippedItems.push(item);
        this.setState({ skippedItems: skippedItems });
    }

    handleItemValueChange(value) {
        var items = this.state.items;
        var currentItemId = this.state.currentItem.itemId;
        var currentItemIndex = items.findIndex(
            (item) => {
                if (item.itemId === currentItemId) {
                    return true;
                }
                return false;
            });

        items[currentItemIndex].itemValue = value;
        this.setState({ items: items });
    }

    handleSkippedItemValueChange(skippedItem) {
        var items = this.state.items;
        var skippedItemId = skippedItem.itemId;
        var itemIndex = items.findIndex(
            (item) => {
                if (item.itemId === skippedItemId) {
                    return true;
                }
                return false;
            });


        items[itemIndex].itemValue = skippedItem.itemValue;

        var skippedItems = this.state.skippedItems;
        var skippedItemIndex = skippedItems.findIndex(
            (item) => {
                if (item.itemId === skippedItemId) {
                    return true;
                }
                return false;
            });
        skippedItems.splice(skippedItemIndex, 1);

        this.setState({
            items: items,
            skippedItems: skippedItems
        });
    }

    backToItemWorkflow() {
        this.setState({ workflowStep: 3 });
    }

    showInterimResult() {
        console.log("Show Result!");
    }

    handlePdfDownload() {
        const supplementscalePdfExporter = new SupplementscalePdfExporter(this.state.items, this.state.questionaireType, this.state.supplementData.patient, this.state.supplementData.assessor);
        supplementscalePdfExporter.createPdf();
    }

    handleQuestionaireTypeChange(value) {
        this.setState({
            questionaireType: value,
            workflowStep: 3
        });
    }

    render() {
        let query = this.props.location.search;

        //Skipping items cannot be accessed in the current version (this is intended!)
        if (this.state.workflowStep === 99) {
            return (
                <Box>
                    <Box m={2}>
                        <PersonCard patient={this.state.supplementData.patient} assessor={this.state.supplementData.assessor} />
                    </Box>
                    <Box m={2}>
                        <SkippedItemsCard backToItemWorkflow={this.backToItemWorkflow} handleSkippedItemValueChange={this.handleSkippedItemValueChange} items={this.state.skippedItems} />
                    </Box>
                </Box>
            );

        } else if (this.state.workflowStep === 4) {

            if (this.props.handleFinished) {
                this.props.handleFinished();
            }

            return (
                <Box>
                    <Box m={2}>
                        <PersonCard patient={this.state.supplementData.patient} assessor={this.state.supplementData.assessor} />
                    </Box>
                    <Box m={2}>
                        <SupplementscaleCanvasCard items={this.state.items} questionaireType={this.state.questionaireType} />
                    </Box>
                    <Box m={2}>
                        <Button variant="contained" color="primary" onClick={this.handlePdfDownload}>Ergebnis herunterladen</Button>
                    </Box>
                </Box>
            );

        } else if (this.state.workflowStep === 3) {
            return (
                <Box>
                    <Box m={2}>
                        <PersonCard patient={this.state.supplementData.patient} assessor={this.state.supplementData.assessor} />
                    </Box>
                    <Box m={2}>
                        <SupplementscaleItem questionaireType={this.state.questionaireType} items={this.state.items} skippedItems={this.state.skippedItems} workflow={true} disableFinish={this.disableFinish()} disableBack={this.disableBack()} disableNext={this.disableNext()} handleFinish={this.handleFinish} handleBack={this.handleBack} handleNext={this.handleNext} handleItemValueChange={this.handleItemValueChange} item={this.state.currentItem} handleSkippedItem={this.handleSkippedItem} navigateToSkippedItemsList={this.navigateToSkippedItemsList} key={this.state.currentItem.itemId} />
                    </Box>
                </Box>
            );
        } else if (this.state.workflowStep === 2) {
            return (
                <Box m={2}>
                    <QualitativeQuantitativeChoiceCard onSubmit={this.handleQuestionaireTypeChange} />
                </Box>
            )
        } else if (this.state.workflowStep === 1) {
            return (
                <Box m={2}>
                    <PersonFormCard link="mainscale" onSubmit={this.handlesupplementData} />
                </Box>
            );
        }
    }
}