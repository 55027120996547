import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import SkipItemDialog from './SkipItemDialog';
import MainscaleInterimResultDialog from './MainscaleInterimResultDialog';
import FinishAssessmentDialog from './FinishAssessmentDialog';

const useStyles = (theme) => ({
    divider: {
        margin: theme.spacing(2),
    }

});

class MainscaleItem extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            itemValue: props.item.itemValue,
            openSkipDialog: false,
            openInterimResultDialog: false,
            openFinishAssessmentDialog: false
        };

        this.handleItemValueChange = this.handleItemValueChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleSkipDialogClose = this.handleSkipDialogClose.bind(this);
        this.handleItemSkip = this.handleItemSkip.bind(this);
        this.handleInterimResultClose = this.handleInterimResultClose.bind(this);
        this.showInterimResult = this.showInterimResult.bind(this);
        this.handleFinishButtonClick = this.handleFinishButtonClick.bind(this);
        this.handleFinishDialogClose = this.handleFinishDialogClose.bind(this);
    }

    handleItemValueChange(event) {
        var value = event.target.value;
        this.props.handleItemValueChange(value);
        this.setState({ itemValue: value });
    }

    handleNext() {
        if (this.state.itemValue) {
            this.props.handleNext();
        } else {
            this.setState({ openSkipDialog: true });
        }
    }

    handleItemSkip() {
        this.props.handleSkippedItem(this.props.item);
        this.props.handleNext();
    }

    handleSkipDialogClose() {
        this.setState({ openSkipDialog: false });
    }
    handleInterimResultClose() {
        this.setState({ openInterimResultDialog: false });
    }

    showInterimResult() {
        this.setState({ openInterimResultDialog: true });
    }

    handleFinishButtonClick() {
        this.setState({ openFinishAssessmentDialog: true });
    }

    handleFinishDialogClose() {
        this.setState({ openFinishAssessmentDialog: false });
    }

    calculateOpenItems() {
        var items = this.props.items;
        var openItemCount = 0;

        items.forEach(element => {
            if (element.itemValue === null) {
                openItemCount++;
            }
        });


        return openItemCount;
    }

    render() {
        const itemIdentifier = this.props.item.itemCategory.categoryAbbrv + "-" + this.props.item.itemCategory.itemCategoryId;
        const { classes } = this.props;
        const workflowButtons = (<div><ButtonGroup color="primary"><Button disabled={this.props.disableBack} onClick={this.props.handleBack}>Vorheriges Item</Button>
            <Button disabled={this.props.disableNext} onClick={this.handleNext}>Nächstes Item</Button>
            <Button onClick={this.showInterimResult}>Zwischenergebnis anzeigen</Button>
        </ButtonGroup>
            {/* <Button disabled={this.props.skippedItems && ((this.props.skippedItems.length > 0) ? false : true)} style={{ marginLeft: '40px' }} variant="contained" color="primary" onClick={this.props.navigateToSkippedItemsList}>Übersprungene Items ansehen</Button> */}
            <Button style={{ marginLeft: '40px' }} variant="contained" color="primary" onClick={this.handleFinishButtonClick}>Einschätzung abschließen</Button>
        </div>);

        return (
            <Card>
                <FinishAssessmentDialog open={this.state.openFinishAssessmentDialog} handleClose={this.handleFinishDialogClose} openItems={this.calculateOpenItems()} handleFinishAssessment={this.props.handleFinish} />
                <MainscaleInterimResultDialog items={this.props.items} questionaireType={this.props.questionaireType} handleClose={this.handleInterimResultClose} open={this.state.openInterimResultDialog} />
                <SkipItemDialog itemIdentifier={itemIdentifier} handleClose={this.handleSkipDialogClose} open={this.state.openSkipDialog} handleItemSkip={this.handleItemSkip} />
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {this.props.item.itemCategory.categoryAbbrv}-{this.props.item.itemCategory.itemCategoryId}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {this.props.item.itemText}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Kommentar: {this.props.item.itemComment}
                    </Typography>
                    <Divider variant="middle" className={classes.divider} />
                    <FormControl component="fieldset">
                        <RadioGroup name="mainscaleItem" value={this.state.itemValue} onChange={this.handleItemValueChange}>
                            <FormControlLabel value="1" control={<Radio />} label="1 - stimmt meistens" />
                            <FormControlLabel value="2a" disabled={this.props.questionaireType === "2"} control={<Radio />} label="2a - Stimmt manchmal - In guten Situationen/bei guter emotionaler Verfassung" />
                            <FormControlLabel value="2b" control={<Radio />} label="2b - Stimmt manchmal - In neutralen Situationen/bei neutraler emotionaler Verfassung" />
                            <FormControlLabel value="2c" disabled={this.props.questionaireType === "2"} control={<Radio />} label="2c - Stimmt manchmal - In schlechten Situationen/bei schlechter emotionaler Verfassung" />
                            <FormControlLabel value="3" control={<Radio />} label="3 - Stimmt nie - Das Merkmal trifft so nie auf/ist nie zu beobachten" />
                        </RadioGroup>
                    </FormControl>
                </CardContent>
                <CardActions>
                    {this.props.workflow && workflowButtons}
                </CardActions>
            </Card>
        );
    }
}

export default withStyles(useStyles)(MainscaleItem);