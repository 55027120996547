import React, { useRef, useEffect } from 'react'
import SupplementscaleCanvas from './SupplementscaleCanvas';

const SupplementscaleCanvasReact = props => {

    const canvasRef = useRef(null)

    useEffect(() => {
        const items = props.items;
        const questionaireType = props.questionaireType;

        const canvas = canvasRef.current;
        // const ctx = canvas.getContext('2d');

        const supplementscaleCanvas = new SupplementscaleCanvas(canvas, items, questionaireType, false);

        canvas.width = supplementscaleCanvas.calculateCanvasWidth();
        canvas.height = supplementscaleCanvas.calculateCanvasHeight();

        console.log("Width:" + canvas.width);
        console.log("Height:" + canvas.height);


        //do some math to find out if rescaling is needed
        const ratio = supplementscaleCanvas.getDevicePixelRatio();
        const width = canvas.width / ratio;
        const height = canvas.height / ratio;

        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;

        // ctx.clearRect(0, 0, canvas.width, canvas.height);


        // drawAgeRanges(ctx, ageRanges);
        // var i = 0;
        // categories.forEach(element => {
        //     drawCategoryBorders(ctx, getItemsByCategory(items, element), getAgeRanges(items), i);
        //     drawCategory(ctx, getItemsByCategory(items, element), getAgeRanges(items), i);
        //     i++;
        // });

        supplementscaleCanvas.draw();



    }, [props.items])

    return <canvas ref={canvasRef} questionaireType={props.questionaireType} /> //{...props}
}

export default SupplementscaleCanvasReact