import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { createCanvas } from 'canvas';
import MainscaleCanvas from '../views/MainscaleCanvas';

export default class MainscalePdfExporter {
    constructor(items, questionaireType, patient, assessor) {
        //just create a canvas, width and height will be calculated later
        this.items = items;
        this.questionaireType = questionaireType;
        this.patient = patient;
        this.assessor = assessor;



        // Default export is a4 paper, portrait, using millimeters for units
        this.pdf = new jsPDF('landscape', 'mm', 'a4');

        this.addTableToPdf();
        this.pdf.addPage('a4', 'portrait');
        this.addCanvasToPdf();

        this.addHeaders();
        this.addFooters();
    }

    getItemsInTableFormat() {
        let itemList = [];

        this.items.forEach(element => {
            let item = [element.itemCategory.categoryAbbrv + "-" + element.itemCategory.itemCategoryId, element.itemType, element.itemText, element.itemComment];

            if (element.itemValue === "1") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "2a") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "2b") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "2c") {
                item.push("x");
            } else {
                item.push("");
            }

            if (element.itemValue === "3") {
                item.push("x");
            } else {
                item.push("");
            }

            //only filled elements should be added
            if (element.itemValue !== null) {
                itemList.push(item);
            }
        });

        return itemList;
    }

    getJsPdfObj() {
        return this.pdf;
    }

    createPdf() {
        this.pdf.save(this.patient.firstname + "_" + this.patient.lastname + "_" + this.patient.birthday.toLocaleDateString("de-DE") + "_Hauptskala.pdf");
    }

    addTableToPdf() {
        this.pdf.autoTable({
            theme: 'striped',
            headStyles: { lineWidth: 0.2, lineColor: [200, 200, 200] },
            bodyStyles: { lineWidth: 0.2, lineColor: [200, 200, 200] },
            head: [
                [
                    { content: 'Dim.', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Typ', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Text', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Kommentar', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Wert', colSpan: 5, rowSpan: 1, styles: { halign: 'center' } },
                ],
                [
                    { content: '1', colSpan: 1, rowSpan: 2, styles: { halign: 'center' } },
                    { content: '2', colSpan: 3, rowSpan: 1, styles: { halign: 'center' } },
                    { content: '3', colSpan: 1, rowSpan: 2, styles: { halign: 'center' } }
                ],
                [
                    { content: 'a', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
                    { content: 'b', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
                    { content: 'c', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } }
                ]
            ],
            body: this.getItemsInTableFormat(),
        })
    }

    addCanvasToPdf() {
        const canvas = createCanvas(0, 0);
        const mainscaleCanvas = new MainscaleCanvas(canvas, this.items, this.questionaireType, true);

        canvas.width = mainscaleCanvas.calculateCanvasWidth();
        canvas.height = mainscaleCanvas.calculateCanvasHeight();

        mainscaleCanvas.draw();

        let imgData = canvas.toDataURL("image/jpeg", 1.0);
        this.pdf.addImage(imgData, 'JPEG', 15, 15);
    }

    addHeaders() {
        const pageCount = this.pdf.internal.getNumberOfPages()

        // doc.setFont('helvetica', 'italic')
        this.pdf.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
            this.pdf.setPage(i)
            this.pdf.text('Klient/in: ' + this.patient.firstname + ' ' + this.patient.lastname + ', geb. ' + this.patient.birthday.toLocaleDateString("de-DE"), 10, 10, {
                align: 'left'
            });
            this.pdf.text('Einschätzer/in: ' + this.assessor.firstname + ' ' + this.assessor.lastname, this.pdf.internal.pageSize.width - 10, 10, {
                align: 'right'
            });

            this.pdf.text('Datum: ' + new Date().toLocaleDateString("de-DE"), this.pdf.internal.pageSize.width / 2, 10, {
                align: 'center'
            });
        }
    }

    addFooters() {
        const pageCount = this.pdf.internal.getNumberOfPages()

        // doc.setFont('helvetica', 'italic')
        this.pdf.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
            this.pdf.setPage(i)
            this.pdf.text('Seite ' + String(i) + '/' + String(pageCount), this.pdf.internal.pageSize.width / 2, this.pdf.internal.pageSize.height - 10, {
                align: 'center'
            });
        }
    }
}



