import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MainscaleCanvasReact from './MainscaleCanvasReact';

export default class MainscaleInterimResultDialog extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">{"Zwischenergebnis"}</DialogTitle>
                <DialogContent>
                    <MainscaleCanvasReact items={this.props.items} questionaireType={this.props.questionaireType} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Erhebung fortsetzen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}