import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { createCanvas } from 'canvas';
import SupplementscaleCanvas from '../views/SupplementscaleCanvas';

export default class SupplementscalePdfExporter {
    constructor(items, questionaireType, patient, assessor, pdf) {
        //just create a canvas, width and height will be calculated later
        this.items = items;
        this.patient = patient;
        this.assessor = assessor;
        this.questionaireType = questionaireType;

        if (!pdf) {
            // Default export is a4 paper, portrait, using millimeters for units
            this.pdf = new jsPDF('landscape', 'mm', 'a4');
        } else {
            this.pdf = pdf;
        }

        this.addTableToPdf();
        this.pdf.addPage('a4', 'portrait');
        this.addCanvasToPdf();
    }

    getItemsInTableFormat() {
        let itemList = [];

        this.items.forEach(element => {
            let item = [element.itemCategory.categoryAbbrv + "-" + element.itemCategory.itemCategoryId, element.itemType, element.itemText, element.itemComment];

            if (element.itemValue.value === "1") {
                if (element.itemValue.situation === "b") {
                    item.push("b");
                } else {
                    item.push("c");
                }

                if (element.itemValue.intensity === "+") {
                    item.push("+");
                } else {
                    item.push("-");
                }
            } else {
                item.push("");
                item.push("");
            }

            if (element.itemValue.value === "2") {
                if (element.itemValue.situation === "b") {
                    item.push("b");
                } else {
                    item.push("c");
                }

                if (element.itemValue.intensity === "+") {
                    item.push("+");
                } else {
                    item.push("-");
                }
            } else {
                item.push("");
                item.push("");
            }

            if (element.itemValue.value === "3") {
                item.push("x");
            } else {
                item.push("");
            }

            //only filled elements should be added
            if (element.itemValue.value !== null) {
                itemList.push(item);
            }
        });

        return itemList;
    }

    createPdf() {
        this.addHeaders();
        this.addFooters();
        this.pdf.save(this.patient.firstname + "_" + this.patient.lastname + "_" + this.patient.birthday.toLocaleDateString("de-DE") + "_Ergänzungsskala.pdf");
    }

    addTableToPdf() {
        this.pdf.autoTable({
            theme: 'striped',
            headStyles: { lineWidth: 0.2, lineColor: [200, 200, 200] },
            bodyStyles: { lineWidth: 0.2, lineColor: [200, 200, 200] },
            head: [
                [
                    { content: 'Dim.', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Typ', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Text', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Kommentar', colSpan: 1, rowSpan: 3, styles: { valign: 'bottom' } },
                    { content: 'Wert', colSpan: 5, rowSpan: 1, styles: { halign: 'center' } },
                ],
                [
                    { content: '1', colSpan: 2, rowSpan: 1, styles: { halign: 'center' } },
                    { content: '2', colSpan: 2, rowSpan: 1, styles: { halign: 'center' } },
                    { content: '3', colSpan: 1, rowSpan: 2, styles: { halign: 'center' } }
                ],
                [
                    { content: 'S/V', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
                    { content: 'i', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
                    { content: 'S/V', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
                    { content: 'i', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } }
                ]
            ],
            body: this.getItemsInTableFormat(),
        })
    }

    addCanvasToPdf() {
        const canvas = createCanvas(0, 0);
        const supplementscaleCanvas = new SupplementscaleCanvas(canvas, this.items, this.questionaireType, true);

        canvas.width = supplementscaleCanvas.calculateCanvasWidth();
        canvas.height = supplementscaleCanvas.calculateCanvasHeight();

        supplementscaleCanvas.draw();

        let imgData = canvas.toDataURL("image/jpeg", 1.0);
        this.pdf.addImage(imgData, 'JPEG', 15, 15);
    }

    addHeaders() {
        const pageCount = this.pdf.internal.getNumberOfPages()

        // doc.setFont('helvetica', 'italic')
        this.pdf.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
            this.pdf.setPage(i)
            this.pdf.text('Klient/in: ' + this.patient.firstname + ' ' + this.patient.lastname + ', geb. ' + this.patient.birthday.toLocaleDateString("de-DE"), 10, 10, {
                align: 'left'
            });
            this.pdf.text('Einschätzer/in: ' + this.assessor.firstname + ' ' + this.assessor.lastname, this.pdf.internal.pageSize.width - 10, 10, {
                align: 'right'
            });

            this.pdf.text('Datum: ' + new Date().toLocaleDateString("de-DE"), this.pdf.internal.pageSize.width / 2, 10, {
                align: 'center'
            });
        }
    }

    addFooters() {
        const pageCount = this.pdf.internal.getNumberOfPages()

        // doc.setFont('helvetica', 'italic')
        this.pdf.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
            this.pdf.setPage(i)
            this.pdf.text('Seite ' + String(i) + '/' + String(pageCount), this.pdf.internal.pageSize.width / 2, this.pdf.internal.pageSize.height - 10, {
                align: 'center'
            });
        }
    }
}



