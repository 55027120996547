import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';




export default class PersonFormCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            questionaireType: null,
            buttonDisabled: true
        };
    }

    render() {

        const _handleValueChange = (event) => {
            var value = event.target.value;
            this.setState({ questionaireType: value, buttonDisabled: false });
        }

        const _handleSubmit = () => {
            this.props.onSubmit(this.state.questionaireType);
        };

        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Quantitativer oder qualitativer Fragebogen?
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup name="questionaireType" value={this.state.questionaireType} onChange={_handleValueChange}>
                            <FormControlLabel value="1" control={<Radio />} label="Qualitativer Fragebogen/Auswertung" />
                            <FormControlLabel value="2" control={<Radio />} label="Quantitativer Fragebogen/Auswertung" />
                        </RadioGroup>
                    </FormControl>
                </CardContent>
                <CardActions>
                    <Button variant="contained" disabled={this.state.buttonDisabled} onClick={_handleSubmit}>Weiter zum nächsten Schritt</Button>
                </CardActions>
            </Card >

        );
    }
}