import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './views/App';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { deDE } from '@material-ui/core/locale';
import reportWebVitals from './reportWebVitals';

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

const theme = createMuiTheme({
  // palette: {
  //   primary: { main: '#1976d2' },
  // },
  typography: {
    fontSize: 10,
  },
}, deDE);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
