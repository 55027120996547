const YELLOW = "#FFFF00";
const BLUE = "#1E90FF";
const WHITE = "#ffffff";
const ORANGE = "#ff8800";
const BLACK = "#ffffff";
const GRAY = "#808080";
const LIGHT_GRAY = "#D3D3D3";


export default class SupplementscaleCanvas {
    constructor(canvas, items, questionaireType, forExport) {
        this.ctx = canvas.getContext('2d');
        this.forExport = forExport;
        this.questionaireType = questionaireType;

        this.items = items;
        // this.mappedItems = this.mapItemsToAgeBand(items);

        this.ageBands = this.getAgeBandList();

        // sort AgeBands upfront desc
        this.ageBands.sort((age1, age2) => {
            if (age1[0] > age2[0]) {
                return -1;
            }
            if (age2[0] > age1[0]) {
                return 1;
            }
            return 0;
        });

        this.draw = this.draw.bind(this);
    }

    getItemsOfAgeBand(ageBand) {
        var agedItems = [];
        this.items.forEach((item) => {
            if (item.itemAge[0] == ageBand[0] && item.itemAge[1] == ageBand[1]) {
                agedItems.push(item);
            }
        });

        return agedItems;
    }

    getAgeBandList() {
        var ageBands = [];
        this.items.forEach((item) => {
            var push = true;
            ageBands.forEach((ageBand) => {
                if (item.itemAge[0] == ageBand[0] && item.itemAge[1] == ageBand[1]) {
                    push = false;
                }
            });
            if (push) {
                ageBands.push(item.itemAge);
            }
        });

        return ageBands;
    }


    drawItem(item, posx, posy) {
        var tile_width = this.getCellWidth() / 2;
        var tile_height = this.getCellHeight() / 2;
        var fillColors = this.getItemTileColors(item);
        var borderColor = GRAY;

        // top left
        this.drawItemTiles(fillColors[0], borderColor, posx, posy, tile_width, tile_height);

        // top right
        this.drawItemTiles(fillColors[1], borderColor, posx + tile_width, posy, tile_width, tile_height);

        // bottom left
        this.drawItemTiles(fillColors[2], borderColor, posx, posy + tile_height, tile_width, tile_height);

        // bottom right
        this.drawItemTiles(fillColors[3], borderColor, posx + tile_width, posy + tile_height, tile_width, tile_height);

        this.ctx.fillStyle = BLACK;
        this.ctx.strokeRect(posx, posy, this.getCellWidth(), this.getCellHeight());
        // this.graphic.drawRect(posx, posy, CELL_WIDTH, CELL_HEIGHT);

        var textPos = (posx + tile_width) - (4 * this.getDevicePixelRatio());

        this.drawText(item.itemCategory.itemCategoryId, this.getStandardFont(), "right", "top", textPos, posy + (4 * this.getDevicePixelRatio()));
    }


    getItemTileColors(item) {
        var tileColors = [];
        var fillColor = null;

        //only quantitative questionaire
        if (this.questionaireType === "2") {
            fillColor = ORANGE;
        } else {
            if (item.itemValue.situation === "b") {
                fillColor = YELLOW;
            } else if (item.itemValue.situation === "c") {
                fillColor = BLUE;
            }
        }

        if (item.itemValue.intensity === "+") {
            tileColors[1] = fillColor;
            tileColors[3] = fillColor;
        } else if (item.itemValue.intensity === "-") {
            tileColors[1] = WHITE;
            tileColors[3] = fillColor;
        } else {
            tileColors[1] = LIGHT_GRAY;
            tileColors[3] = LIGHT_GRAY;
        }

        if (item.itemValue.value === "1") {
            tileColors[0] = fillColor;
            tileColors[2] = fillColor;
        } else if (item.itemValue.value === "2") {
            tileColors[0] = WHITE;
            tileColors[2] = fillColor;
        } else {
            tileColors[0] = LIGHT_GRAY;
            tileColors[2] = LIGHT_GRAY;
        }

        if (item.itemValue.value === "3") {
            // nothing should be painted, so overwrite the colors from intensity
            tileColors[0] = WHITE;
            tileColors[1] = WHITE;
            tileColors[2] = WHITE;
            tileColors[3] = WHITE;
        }

        return tileColors;
    }

    drawItemTiles(fillColor, borderColor, posx, posy, width, height) {
        // Stroke stroke = new BasicStroke(1);
        // graphic.setStroke(stroke);

        this.ctx.fillStyle = fillColor;
        this.ctx.fillRect(posx, posy, width, height);

        this.ctx.fillStyle = borderColor;
        this.ctx.strokeRect(posx, posy, width, height);
    }


    drawAgeBand(ageBand, items, posy) {
        // draw Ages information
        this.ctx.fillStyle = WHITE;
        this.ctx.fillRect(0, posy, this.getCellWidth(), this.getCellHeight() * this.getAgeBandColumnCount(items));
        this.ctx.fillStyle = BLACK;
        this.ctx.strokeRect(0, posy, this.getCellWidth(), this.getCellHeight() * this.getAgeBandColumnCount(items));
        // graphic.drawRect(BORDER_LEFT, posy, CELL_WIDTH, CELL_HEIGHT * getAgeBandColumnCount(items));

        var ageBandString = "";
        if (ageBand[0] <= 24) {
            ageBandString = ageBand[0] + " - " + ageBand[1] + " Monate";
        } else {
            ageBandString = (ageBand[0] / 12) + " - " + (ageBand[1] / 12) + " Jahre";
        }

        this.drawText(ageBandString, this.getHeadlineFont(), "left", "middle", 0 + (4 * this.getDevicePixelRatio()), posy + (this.getCellHeight() * this.getAgeBandColumnCount(items) / 2));
        // drawText(ageBand.toString(), FONT_SIZE, BORDER_LEFT + 5, posy + 2);

        // draw AgeBand items
        this.getItemsMappedToCols(items).forEach((colItems) => {
            this.drawColumn(colItems, this.getCellWidth(), posy);
            posy = posy + this.getCellHeight();
        })
    }


    drawFrequencyIntensity(posy) {

        //avoid black box in export for pdf
        this.ctx.fillStyle = WHITE;
        this.ctx.fillRect(0, posy, this.getCellWidth(), this.getCellHeight() / 2);
        this.ctx.fillStyle = BLACK;
        this.ctx.strokeRect(0, posy, this.getCellWidth(), this.getCellHeight() / 2);

        for (var i = 1; i <= this.getCellsPerColumn(); i++) {
            // graphic.drawRect(BORDER_LEFT + (i * CELL_WIDTH), posy, CELL_WIDTH / 2, CELL_HEIGHT / 2);
            this.ctx.fillStyle = WHITE;
            this.ctx.fillRect(0 + (i * this.getCellWidth()), posy, this.getCellWidth() / 2, this.getCellHeight() / 2);
            this.ctx.fillStyle = BLACK;
            this.ctx.strokeRect(0 + (i * this.getCellWidth()), posy, this.getCellWidth() / 2, this.getCellHeight() / 2);
            this.drawText("h", this.getHeadlineFont(), "center", "middle", 0 + (i * this.getCellWidth()) + (this.getCellWidth() / 4), posy + this.getCellHeight() / 4);

            // graphic.drawRect(BORDER_LEFT + (i * CELL_WIDTH) + (CELL_WIDTH / 2), posy, CELL_WIDTH / 2, CELL_HEIGHT / 2);
            this.ctx.fillStyle = WHITE;
            this.ctx.fillRect(0 + (i * this.getCellWidth()) + (this.getCellWidth() / 2), posy, this.getCellWidth() / 2, this.getCellHeight() / 2);
            this.ctx.fillStyle = BLACK;
            this.ctx.strokeRect(0 + (i * this.getCellWidth()) + (this.getCellWidth() / 2), posy, this.getCellWidth() / 2, this.getCellHeight() / 2);
            this.drawText("i", this.getHeadlineFont(), "center", "middle", (i * this.getCellWidth()) + (this.getCellWidth() / 2) + (this.getCellWidth() / 4), posy + this.getCellHeight() / 4);
        }

        return posy + (this.getCellHeight() / 2);
    }

    getItemsMappedToCols(items) {
        var colItems = [];

        // initialize the lists
        for (var i = 0; i < this.getAgeBandColumnCount(items); i++) {
            colItems.push([]);
        }

        // add items to the right list
        for (var i = 0; i < items.length; i++) {
            var colNum = Math.floor(i / this.getCellsPerColumn());

            // We need to resort everything in the reverse order to fit it to the template
            var reverseColNum = (this.getAgeBandColumnCount(items) - 1) - colNum;
            colItems[reverseColNum].push(items[i]);
        }

        return colItems;
    }

    drawColumn(colItems, posx, posy) {
        var gray_posx = posx;
        for (var i = 0; i < this.getCellsPerColumn(); i++) {
            // Fill all empty boxes with gray
            this.ctx.fillStyle = GRAY;
            this.ctx.fillRect(gray_posx, posy, this.getCellWidth(), this.getCellHeight());
            // graphic.fillRect(gray_posx, posy, CELL_WIDTH, CELL_HEIGHT);
            gray_posx = gray_posx + this.getCellWidth();
        }

        colItems.forEach((item) => {
            // draw item boxes
            this.drawItem(item, posx, posy);
            posx = posx + this.getCellWidth();
        })
    }

    drawText(str, fontType, textAlign, textBaseline, posx, posy) {

        this.ctx.font = fontType;
        this.ctx.textAlign = textAlign;
        this.ctx.textBaseline = textBaseline;
        this.ctx.fillStyle = '#000000';

        this.ctx.fillText(str, posx, posy);
    }

    getAgeBandColumnCount(items) {
        var columns = items.length / this.getCellsPerColumn();
        return Math.ceil(columns);
    }


    draw() {

        // start drawing inside the visible area
        var posy = this.getTopBorder();
        this.ageBands.forEach((ageBand) => {
            this.drawAgeBand(ageBand, this.getItemsOfAgeBand(ageBand), posy);
            posy = posy + (this.getAgeBandColumnCount(this.getItemsOfAgeBand(ageBand)) * this.getCellHeight());
        })


        posy = this.drawFrequencyIntensity(posy);
        this.drawCaption(posy);
        this.drawFrame();
    }

    drawCaption(y) {

        //avoid black box in export for pdf
        this.ctx.fillStyle = WHITE;
        this.ctx.fillRect(0, y, this.calculateCanvasWidth(), this.getCaptionHeight());


        y = y + (5 * this.getDevicePixelRatio());


        var x = 4 * this.getDevicePixelRatio();

        this.drawText("Legende:", this.getCaptionFont(), "left", "top", 4 * this.getDevicePixelRatio(), y);


        var h = this.getCaptionFontHeight();
        y = y + h;
        this.drawText("h = Häufigkeit: meistens/oft = zwei Kästchen; manchmal/selten = ein Kästchen", this.getCaptionFont(), "left", "top", x, y);

        y = y + h;
        this.drawText("i = Intensität: + = hoch = zwei Kästchen; - = niedrig = ein Kästchen", this.getCaptionFont(), "left", "top", x, y);
        y = y + h;
        this.drawText("gelb = durchschnittliche Situation / emotionale Verfassung (bei qualitativer Erhebung)", this.getCaptionFont(), "left", "top", x, y);
        y = y + h;
        this.drawText("blau = schwierige Situation / schlechte emotionale Verfassung (bei qualitativer Erhebung)", this.getCaptionFont(), "left", "top", x, y);
        y = y + h;
        this.drawText("orange = Situation / Verfassung nicht erfasst (bei quantitativer Erhebung)", this.getCaptionFont(), "left", "top", x, y);
        y = y + h;
        this.drawText("weiß = nie/nicht beobachtet", this.getCaptionFont(), "left", "top", x, y);
        y = y + h;
        this.drawText("grau unterlegt = Leerstelle", this.getCaptionFont(), "left", "top", x, y);
    }

    getCaptionHeight() {
        return (this.getCaptionFontHeight() * 8) + (5 * this.getDevicePixelRatio());
    }

    drawFrame() {
        this.ctx.fillStyle = BLACK;

        this.ctx.lineWidth = (2 * this.getDevicePixelRatio());
        this.ctx.strokeRect(1, this.getTopBorder() + 1, this.calculateCanvasWidth() - 2, this.calculateCanvasHeight() - this.getCaptionHeight() - 2);
        // graphic.drawRect(BORDER_LEFT, BORDER_TOP, getImageWidth() - (2 * BORDER_LEFT),
        //     getImageHeight() - (2 * BORDER_TOP));
    }

    // private int getImageWidth() {
    //     return (BORDER_LEFT * 2) + (4 * CELL_WIDTH);
    // }

    getColumnCount() {
        var colCount = 0;
        this.ageBands.forEach((ageBand) => {
            colCount = colCount + this.getAgeBandColumnCount(this.getItemsOfAgeBand(ageBand));
        })
        return colCount;
    }

    // private int getImageHeight() {
    //     return (BORDER_TOP * 2) + (getColumnCount() * CELL_HEIGHT) + (CELL_HEIGHT / 2);
    // }

    //returns height of X-Axis
    getXHeight() {
        //TODO
        return 20 * this.getDevicePixelRatio();
    }

    //returns width of Y-Axis
    getYWidth() {
        //TODO
        return 60 * this.getDevicePixelRatio();
    }

    // getAgeRangeHeight() {
    //     return 30 * this.getDevicePixelRatio();
    // }

    // getCategoryWidth() {
    //     return 80 * this.getDevicePixelRatio();
    // }

    getDevicePixelRatio() {
        if (!this.forExport) {
            return window.devicePixelRatio;
        } else {
            return 1.0;
        }
    }

    getHeadlineFont() {
        let size = this.getHeadlineFontHeight();
        return size + "px Calibri";
    }

    getHeadlineFontHeight() {
        return this.getDevicePixelRatio() * 14;
    }

    getStandardFont() {
        let size = this.getStandardFontHeight()
        return size + "px Calibri";
    }

    getStandardFontHeight() {
        return this.getDevicePixelRatio() * 12;
    }

    getCaptionFont() {
        let size = this.getCaptionFontHeight()
        return size + "px Calibri";
    }

    getCaptionFontHeight() {
        return this.getDevicePixelRatio() * 10;
    }

    getLineWidth() {
        return 1 * this.getDevicePixelRatio();
    }

    getTopBorder() {
        return 0 * this.getDevicePixelRatio();
    }

    getLegendHeight() {
        return (34 * this.getDevicePixelRatio()) + this.getHeadlineFontHeight() + (this.getStandardFontHeight() * 5);
    }

    getCellHeight() {
        return 50 * this.getDevicePixelRatio();
    }

    getCellWidth() {
        return 120 * this.getDevicePixelRatio();
    }

    getCellsPerColumn() {
        return 3;
    }

    calculateCanvasHeight() {
        //TODO
        // const ageRangesCount = this.getAgeRanges(this.items).length;
        // return (ageRangesCount * this.getAgeRangeHeight()) + this.getXHeight() + this.getTopBorder() + this.getLegendHeight();
        // return 1000;
        return (this.getTopBorder()) + (this.getColumnCount() * this.getCellHeight()) + (this.getCellHeight() / 2) + this.getCaptionHeight();
    }

    calculateCanvasWidth() {
        //TODO
        // const categoryCount = this.getItemCategories(this.items).length;
        // return (categoryCount * this.getCategoryWidth()) + this.getYWidth();
        // return 1000;
        return (4 * this.getCellWidth());
    }
}

// public class SupplementPlotCanvas extends Canvas {

//     // private int CELL_HEIGHT = (int)(0.0556 * Toolkit.getDefaultToolkit().getScreenSize().getHeight());
//     // private int CELL_WIDTH = (int)(0.0781 * Toolkit.getDefaultToolkit().getScreenSize().getWidth());
//     // private final int CELLS_PER_COLUMN = 3;
//     // private final int BORDER_TOP = 20;
//     private final int BORDER_LEFT = 20;
//     // private final int FONT_SIZE = 14;

//     // private Graphics2D graphic;

//     // private static final long serialVersionUID = 1L;
//     // private HashMap<AgeBand, List<SupplementItem>> mappedItems;

//     public SupplementPlotCanvas(Vector<SupplementItem> items) {
//     mappedItems = new HashMap < AgeBand, List < SupplementItem >> ();
//     mapItemsToAgeBand(items);

//     setBackground(Color.white);

// }


















// }
