import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MainscaleItem from './MainscaleItem';

export default class AssessSkippedItemDialog extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            itemValue: null,
            valueAvailable: false
        }

        this.handleItemValueChange = this.handleItemValueChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleItem = this.handleItem.bind(this);
    }

    handleItemValueChange(value) {
        this.setState({
            itemValue: value,
            valueAvailable: true
        });
    }

    handleClose() {
        this.setState({
            itemValue: null,
            valueAvailable: false
        });
        this.props.handleClose();
    }

    handleItem() {
        var item = this.props.item;
        item.itemValue = this.state.itemValue;
        console.log(item);
        this.props.handleSkippedItemValueChange(item);
        this.handleClose();
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">{"Übersprungenes Item erfassen"}</DialogTitle>
                <DialogContent>
                    <MainscaleItem handleItemValueChange={this.handleItemValueChange} item={this.props.item} key={this.props.item.itemId} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button disabled={!this.state.valueAvailable} onClick={this.handleItem} color="primary" autoFocus>
                        Item speichern
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}