import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function AssessmentTypeCard(props) {
    const classes = useStyles();

    const link = "/new-assessment/" + props.link;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const scaleHint = () => {
        if (props.name === "Hauptskala" || props.name === "Ergänzungsskala") {
            return (
                <Typography style={{ fontWeight: 600 }}>HINWEIS: Durch Ihre Auswahl können Sie NUR die {props.name} einschätzen!</Typography>
            );
        } else {
            return;
        }
    }

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {props.name}
                </Typography>
                <Typography variant="body2" component="p">
                    {props.text}
                </Typography>
            </CardContent>
            <CardActions>
                <Button variant="contained" onClick={handleClickOpen}>Neue Einschätzung</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">{"Neue Einschätzung starten?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-description">
                            <Typography gutterBottom>
                                Möchten Sie eine neue Einschätzung der {props.name} starten? Bitte beachten Sie, dass es sich hierbei um ein kostenpflichtiges Angebot handelt und Ihnen mit der Bestätigung dieses Dialoges eine Rechnung zugesendet wird.
                            </Typography>
                            {scaleHint()}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={handleClose} color="primary" component={Link} to={link} autoFocus>
                            Einschätzung starten
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardActions>
        </Card>
    );
};