import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MainscaleCanvasReact from './MainscaleCanvasReact';

const items = [

    {
        itemId: 92,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 1 },
        itemType: "n",
        itemAge: [0, 4],
        itemText: "beruhigt sich durch freundliche Stimme oder Musik",
        itemComment: "",

        itemValue: "1"
    },
    {
        itemId: 93,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 2 },
        itemType: "i",
        itemAge: [4, 8],
        itemText: "erfasst den emotionalen Sinn des Gesagten über Mimik oder den Sprachklang",
        itemComment: "",
        itemSuccessor: 94,
        itemValue: "2a"
    },
    {
        itemId: 94,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 3 },
        itemType: "i",
        itemAge: [12, 18],
        itemText: "versteht Äußerungen, die sich auf die gegenwärtige Situation beziehen",
        itemComment: "",
        itemSuccessor: 96,
        itemValue: "2c"
    },
    {
        itemId: 95,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 4 },
        itemType: "n",
        itemAge: [18, 24],
        itemText: "erkennt Dinge auf Abbildungen, d.h. sucht oder zeigt auf Befragen bekannte Gegenstände (z.B. Bilderbücher, Fotos, Fernsehen)",
        itemComment: "Teil der Symbolfunktion; wichtig für die Begriffsbildung",

        itemValue: "2a"
    },
    {
        itemId: 96,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 5 },
        itemType: "i",
        itemAge: [30, 36],
        itemText: "versteht viele sprachliche Inhalte, die sich auf das vertraute Alltagsleben beziehen, d.h. Aussagen über nicht Gegenwärtiges, das aber zu seinem gewohnten Alltagsablauf gehört",
        itemComment: "",
        itemSuccessor: 97,
        itemValue: "3"
    },
    {
        itemId: 97,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 6 },
        itemType: "n",
        itemAge: [36, 48],
        itemText: "versteht Erklärungen, die aus der eigenen Erfahrung nachvollziehbar sind",
        itemComment: "",

        itemValue: "3"
    },
    {
        itemId: 98,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 7 },
        itemType: "n",
        itemAge: [48, 60],
        itemText: "hört gern längere Erzählungen / Geschichten ohne Anschauungsmaterial, versteht den Sinn (z.B. Erlebnisberichte, Märchen)",
        itemComment: "",

        itemValue: "3"
    },
    {
        itemId: 99,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 8 },
        itemType: "n",
        itemAge: [60, 72],
        itemText: "befolgt drei einfache, voneinander unabhängige, gleichzeitig gegebene Aufforderungen (z.B. bring den Teller in die Küche, hole das Eis aus dem Kühlschrank und bring einen großen Löffel aus der Schublade mit)",
        itemComment: "",

        itemValue: "3"
    },
    {
        itemId: 100,
        itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 9 },
        itemType: "n",
        itemAge: [72, 96],
        itemText: "versteht als Zuhörer auf umgangssprachlichem Niveau geführte Gespräche/Berichte über alle gängigen Themen (z.B. Alltagsfragen, Urlaub, Sport, Arbeit), d.h. zeigt Interesse, macht passende Kommentare, bezieht sich evtl. zu einem späteren Zeitpunkt auf zentrale Inhalte",
        itemComment: "",

        itemValue: "3"
    }, {
        itemId: 1,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 1 },
        itemType: "n",
        itemAge: [0, 4],
        itemText: "genießt passiv (z.B. entspannt sich beim Baden/Musikhören/In-der-Sonne-sitzen)",
        itemComment: "empfangende Genussfähigkeit, auch als Teil der Sexualität im weitesten Sinne",

        itemValue: "1"
    },
    {
        itemId: 2,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 2 },
        itemType: "f",
        itemAge: [0, 4],
        itemText: "übernimmt die Stimmung der Bezugsperson/Umgebung unmittelbar (z.B. Stress, Ausgeglichenheit), d.h. reagiert auf die Atmosphäre",
        itemComment: "Affektansteckung; geschieht automatisch, d.h. unbewusst. Zeichen fehlender Abgrenzungsfähigkeit",
        itemSuccessor: 26,
        itemValue: "2b"
    },
    {
        itemId: 3,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 3 },
        itemType: "f",
        itemAge: [0, 4],
        itemText: "reagiert bei kürzerem Alleinsein (ca. 15 min.) mit Passivität, Weinen, Spannung oder Unruhe",
        itemComment: "Verlassenheitsreaktion; Angst setzt erst bei / nach der Trennung ein",
        itemSuccessor: 6,
        itemValue: "2a"
    },
    {
        itemId: 4,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 4 },
        itemType: "f",
        itemAge: [0, 4],
        itemText: "findet bei Missbehagen nur durch die unmittelbare Zuwendung (z.B. durch freundliche Ansprache, Körperkontakt, daneben sitzen) einer einfühlsam reagierenden Bezugsperson (die z.B. das Reizniveau steuert, Bedürfnisse befriedigt) zu emotionaler Ausgeglichenheit; erfolgt dies nicht, verfällt er in Apathie/Unruhe",
        itemComment: "Affektregulierung = Steuerung der Ausdrucksstärke von Gefühlen; gemeint ist sowohl die Besänftigung von Angst, Wut, Verzweiflung als auch die Überwindung von emotionaler Ausdruckslosigkeit u. Interesselosigkeit",
        itemSuccessor: 6,
        itemValue: "2a"
    },
    {
        itemId: 5,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 5 },
        itemType: "f",
        itemAge: [4, 8],
        itemText: "beschäftigt sich interessiert und aufmerksam nur, wenn die Bezugsperson physisch und psychisch ansprechbar im Raum ist, d.h. sie kann einer eigenen Tätigkeit nachgehen",
        itemComment: "unsichtbares emotionales Band zur Bezugsperson, Sichtkontakt.",
        itemSuccessor: 10,
        itemValue: "2c"
    },
    {
        itemId: 6,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 6 },
        itemType: "f",
        itemAge: [8, 12],
        itemText: "benutzt, um schwierige Situationen (z.B. kurzfristige Trennung - ca. 15 Minuten) zu meistern, einen Lieblingsgegenstand (z.B. Schmusedecke, Stofftier, Kleidungsstück der Bezugsperson)",
        itemComment: "Übergangsobjekt ist Stellvertreter für die Bezugsperson und die eigene Person; dient dazu, Übergänge u. andere Situationen ohne Bezugsperson zu erleichtern, hilft also zur Verselbständigung u. Zuwendung zur Welt",
        itemSuccessor: 14,
        itemValue: "1"
    },
    {
        itemId: 7,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 7 },
        itemType: "f",
        itemAge: [8, 12],
        itemText: "hat heftige Erregungszustände bis hin zum Kontrollverlust (erkennbar an irrem Blick, fehlender Ansprechbarkeit) bei geringfügigen oder nicht erkennbaren Anlässen (z.B. geringfügige Einschränkungen, Störung eines gewohnten Ablaufs, Unterbrechen einer Tätigkeit, kurzes Warten)",
        itemComment: "Erlebenshintergrund: existentiell bedrohliche Verwirrung und Desorientierung, Verlust der Selbststeuerung",
        itemSuccessor: 14,
        itemValue: "1"
    },
    {
        itemId: 8,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 8 },
        itemType: "n",
        itemAge: [12, 18],
        itemText: "freut sich an seinem Körper (Bewegung, Größe, Schnelligkeit)",
        itemComment: "positives Körpererleben auch als Bestandteil sexueller Genussfähigkeit im weitesten Sinne",

        itemValue: "2b"
    },
    {
        itemId: 9,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 9 },
        itemType: "f",
        itemAge: [12, 18],
        itemText: "ist stolz auf sein Tun unabhängig von der Qualität des Ergebnisses (z.B. strahlt, aufrechte Körperhaltung, will Bestätigung)",
        itemComment: "Das Tun als solches reicht für den Stolz; Selbstwirksamkeit als Wurzel für die spätere Leistungsmotivation",
        itemSuccessor: 12,
        itemValue: "1"
    },
    {
        itemId: 10,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 10 },
        itemType: "i",
        itemAge: [12, 18],
        itemText: "beschäftigt sich 15 - 20 min. aufmerksam allein, wenn die Bezugsperson zwar nicht sichtbar, aber in der Nähe und erreichbar (z.B. im Nebenzimmer) ist",
        itemComment: "Zeichen zunehmender Autonomie",
        itemSuccessor: 18,
        itemValue: "2b"
    },
    {
        itemId: 11,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 11 },
        itemType: "f",
        itemAge: [18, 24],
        itemText: "verweigert sich / benutzt \"Nein\" nicht sachbezogen, sondern zur Selbstbehauptung - nicht aus Überforderung mit der Situation - (z.B. verweigert etwas, was er mag; lehnt Hilfe ab)",
        itemComment: "Angst vor Autonomieverlust",
        itemSuccessor: 36,
        itemValue: "2b"
    },
    {
        itemId: 12,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 12 },
        itemType: "f",
        itemAge: [24, 30],
        itemText: "reagiert bei Misserfolg mit heftiger Wut / Verzweiflung ohne Kontrollverlust (z.B. Schreien, Um-Sich-Schlagen)",
        itemComment: "geringe Frustrationstoleranz, weil er sich seiner begrenzten Fähigkeiten u. Abhängigkeit bewusst wird",
        itemSuccessor: 17,
        itemValue: "2a"
    },
    {
        itemId: 13,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 13 },
        itemType: "n",
        itemAge: [24, 30],
        itemText: "äußert klare Geschmacksvorstellungen oder Vorlieben (z.B. Lieblingsgericht, bestimmte Kleidung, Tätigkeiten)",
        itemComment: "Zeichen von Autonomie",

        itemValue: "1"
    },
    {
        itemId: 14,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 14 },
        itemType: "n",
        itemAge: [30, 36],
        itemText: "löst eine emotional schwierige Alltagssituation nach dem Muster der Bezugsperson, d.h. greift bei emotionalen Schwierigkeiten auf seine guten Erfahrungen mit ihr zurück (z.B. beruhigt sich oder einen anderen \"..kommt wieder\", \"..machen wir später\", \"…macht nichts\")",
        itemComment: "hat die konstruktiven Bewältigungsformen der \"guten\" Bezugsperson verinnerlicht. Bestandteil der sicheren Bindung",

        itemValue: null
    },
    {
        itemId: 15,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 15 },
        itemType: "n",
        itemAge: [30, 36],
        itemText: "versucht nach Fehlverhalten negative Reaktionen der Bezugsperson zu verhindern (z.B. passt sich an, will wieder \"lieb\" sein, entschuldigt sich) bevor diese reagiert, weil er sich seiner Normübertretung bewusst ist",
        itemComment: "Angst vor Strafe und Liebesverlust als Weiterentwicklung der Trennungsangst; Beginn des Schuldbewusstseins, Normen sind ansatzweise verinnerlicht.",

        itemValue: null
    },
    {
        itemId: 16,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 16 },
        itemType: "i",
        itemAge: [36, 48],
        itemText: "hält viele Gebote für begrenzte Zeit (ca. 30 Minuten) ohne Aufsicht ein",
        itemComment: "Zeichen von wachsender Selbstkontrolle und Normübernahme",
        itemSuccessor: 39,
        itemValue: null
    },
    {
        itemId: 17,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 17 },
        itemType: "n",
        itemAge: [36, 48],
        itemText: "bemüht sich Aufgaben / Vorhaben zunächst allein zu bewältigen, sucht jedoch vertrauensvoll Hilfe, wenn es ihm nicht gelingt, d.h. verbindet Autonomie mit sozialer Bezogenheit",
        itemComment: "Bestandteil der sicheren Bindung, emotionale Konstanz",

        itemValue: null
    },
    {
        itemId: 18,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 18 },
        itemType: "n",
        itemAge: [48, 60],
        itemText: "nutzt ein breites Repertoire (mindestens fünf Möglichkeiten) und beschäftigt sich damit mindestens 30-45 Minuten aktiv, solange er jederzeit eine Bezugsperson aufsuchen kann",
        itemComment: "Zeichen von Autonomie und Interessensvielfalt",
        itemSuccessor: 45,
        itemValue: null
    },
    {
        itemId: 19,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 19 },
        itemType: "n",
        itemAge: [60, 72],
        itemText: "schätzt seine Fähigkeiten häufig realistisch ein (z.B. weiß, dass er neue Aufgaben erst üben muss; setzt sich relativ realistische Ziele)",
        itemComment: "beginnende Fähigkeit zur Selbstreflexion; Erfolgsorientierung als Aspekt der Leistungsmotivation",

        itemValue: null
    },
    {
        itemId: 20,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 20 },
        itemType: "n",
        itemAge: [60, 72],
        itemText: "reguliert Affekte mittlerer Stärke (z.B. Ärger, Freude, Traurigkeit) auf unterschiedliche Weise konstruktiv; d.h. nutzt ein breites Verhaltensrepertoire von mindestens drei Möglichkeiten (z.B. Übergangsprojekt, eine Lieblingsbeschäftigung, Trost suchen bei einer Bezugsperson, gedankliche Selbstberuhigung)",
        itemComment: "besitzt Affekt- und Impulskontrolle als Teil der Selbstkontrolle",

        itemValue: null
    },
    {
        itemId: 21,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 21 },
        itemType: "n",
        itemAge: [72, 96],
        itemText: "reflektiert mit der Bezugsperson aus eigenem Antrieb alle ihn beschäftigenden \"Lebensfragen\" (z.B. \"Warum schlägt X Schwächere und hört nicht auf, wenn sie weinen?\", \"Warum kann ich das nicht, obwohl ich mich anstrenge?\") und fragt gezielt weiter, bis ihm die Erklärung genügt",
        itemComment: "Bezugsperson als \"Weltdeuter\" und \"Lebensberater\"",

        itemValue: null
    },
    {
        itemId: 22,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 22 },
        itemType: "n",
        itemAge: [96, 144],
        itemText: "beschäftigt sich allein ausdauernd und konzentriert (mind. 1 Std.) mit einer vorgegebenen Aufgabe (z.B. Hausaufgaben, Haushaltstätigkeiten, WfbM-Arbeiten)",
        itemComment: "Anstrengungsbereitschaft als Teil der Leistungsmotivation",

        itemValue: null
    },
    {
        itemId: 23,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 23 },
        itemType: "n",
        itemAge: [144, 216],
        itemText: "setzt sich allein oder mit Gleichaltrigen differenziert mit verschiedenen Themen seiner Lebenssituation auseinander (Familie, Herkunft, sozialer Status, Behinderung, Zukunftspläne)",
        itemComment: "differenzierte Selbstreflexion",

        itemValue: null
    },
    {
        itemId: 24,
        itemCategory: { categoryAbbrv: "Em", itemCategoryId: 24 },
        itemType: "n",
        itemAge: [216, 1188],
        itemText: "trifft weitreichende Entscheidungen selbstverantwortlich und geht  auch mit unangenehmen Konsequenzen konstruktiv um, d.h. hat gute  Realitäts- und Selbsteinschätzung, Selbstkontrolle und angemessene Selbstbehauptung",
        itemComment: "Merkmal der erwachsenen selbstverantwortlichen Persönlichkeit",

        itemValue: null
    },
];

export default class MainscaleCanvasCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <MainscaleCanvasReact items={this.props.items} questionaireType={this.props.questionaireType} />
                </CardContent>
            </Card>
        );
    }
}