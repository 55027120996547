import React from 'react';
import 'date-fns';
import deLocale from "date-fns/locale/de";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';



export default class PersonFormCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            'patient-birthday': new Date('1990-01-01T00:00:00'),
            password_error_text: "lalala"
        };
        this.state['patient-firstname'] = "";
        this.state['patient-lastname'] = "";
        this.state['assessor-firstname'] = "";
        this.state['assessor-lastname'] = "";
        this.state['patient-firstname-error'] = { error: false, text: "" };
        this.state['patient-lastname-error'] = { error: false, text: "" };
        this.state['assessor-firstname-error'] = { error: false, text: "" };
        this.state['assessor-lastname-error'] = { error: false, text: "" };
    }

    render() {

        const _handleDateChange = (date) => {
            this.setState({ 'patient-birthday': date });
        };

        const _handleTextFieldChange = (e) => {
            this.setState({
                [e.target.id]: e.target.value
            });
        }

        const _handleSubmit = () => {
            var error = false;
            var newState = {};

            if (this.state['patient-firstname'].length === 0) {
                error = true;
                newState['patient-firstname-error'] = { error: true, text: "Bitte geben Sie einen Vornamen ein." };
            } else {
                newState['patient-firstname-error'] = { error: false, text: "" };
            }
            if (this.state['patient-lastname'].length === 0) {
                error = true;
                newState['patient-lastname-error'] = { error: true, text: "Bitte geben Sie einen Nachnamen ein." };
            } else {
                newState['patient-lastname-error'] = { error: false, text: "" };
            }
            if (this.state['assessor-firstname'].length === 0) {
                error = true;
                newState['assessor-firstname-error'] = { error: true, text: "Bitte geben Sie einen Vornamen ein." };
            } else {
                newState['assessor-firstname-error'] = { error: false, text: "" };
            }
            if (this.state['assessor-lastname'].length === 0) {
                error = true;
                newState['assessor-lastname-error'] = { error: true, text: "Bitte geben Sie einen Nachnamen ein." };
            } else {
                newState['assessor-lastname-error'] = { error: false, text: "" };
            }
            this.setState(newState);

            if (!error) {
                this.props.onSubmit({
                    patient: {
                        firstname: this.state['patient-firstname'],
                        lastname: this.state['patient-lastname'],
                        birthday: this.state['patient-birthday']
                    },
                    assessor: {
                        firstname: this.state['assessor-firstname'],
                        lastname: this.state['assessor-lastname']
                    },
                    assessment: {
                        date: new Date(),
                    }
                })
            }
        };

        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Stammdaten
                    </Typography>
                    <Typography variant="h6">
                        Klient/in
                    </Typography>
                    <Typography paragraph>
                        <TextField required id="patient-firstname" label="Vorname" onChange={_handleTextFieldChange} helperText={this.state['patient-firstname-error'].text} error={this.state['patient-firstname-error'].error} />
                        <TextField required id="patient-lastname" label="Nachname" onChange={_handleTextFieldChange} helperText={this.state['patient-lastname-error'].text} error={this.state['patient-lastname-error'].error} />
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                            <KeyboardDatePicker
                                required
                                //autoOk
                                //variant="inline"
                                //inputVariant="outlined"
                                label="Geburtsdatum"
                                format="dd.MM.yyyy"
                                value={this.state['patient-birthday']}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => _handleDateChange(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </Typography>
                    <Typography variant="h1" gutterBottom />
                    <Typography variant="h6" gutterBottom>
                        Einschätzer/in
                    </Typography>
                    <Typography>
                        <TextField required id="assessor-firstname" label="Vorname" onChange={_handleTextFieldChange} helperText={this.state['assessor-firstname-error'].text} error={this.state['assessor-firstname-error'].error} />
                        <TextField required id="assessor-lastname" label="Nachname" onChange={_handleTextFieldChange} helperText={this.state['assessor-lastname-error'].text} error={this.state['assessor-lastname-error'].error} />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant="contained" onClick={_handleSubmit}>Weiter zum nächsten Schritt</Button>
                </CardActions>
            </Card >

        );
    }
}