import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class SkipItemDialog extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">{"Item überspringen?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Möchten Sie die Einschätzung des Items {this.props.itemIdentifier} überspringen?
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Abbrechen
                         </Button>
                    <Button onClick={this.props.handleItemSkip} color="primary" autoFocus>
                        Item überspringen
                        </Button>
                </DialogActions>
            </Dialog>
        );
    }
}