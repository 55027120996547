import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import SkipItemDialog from './SkipItemDialog';
import SupplementscaleInterimResultDialog from './SupplementscaleInterimResultDialog';

const useStyles = (theme) => ({
    divider: {
        margin: theme.spacing(2),
    }

});

class SupplementscaleItem extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            itemValue: props.item.itemValue,
            openSkipDialog: false,
            openInterimResultDialog: false
        };

        this.handleItemValueChange = this.handleItemValueChange.bind(this);
        this.handleItemValueIntensityChange = this.handleItemValueIntensityChange.bind(this);
        this.handleItemValueSituationChange = this.handleItemValueSituationChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleSkipDialogClose = this.handleSkipDialogClose.bind(this);
        this.handleItemSkip = this.handleItemSkip.bind(this);
        this.handleInterimResultClose = this.handleInterimResultClose.bind(this);
        this.showInterimResult = this.showInterimResult.bind(this);
        this.checkDisableSituation = this.checkDisableSituation.bind(this);
        this.checkDisableIntensity = this.checkDisableIntensity.bind(this);
        this.getSituationHeadlineTextColor = this.getSituationHeadlineTextColor.bind(this);
        this.getIntensityHeadlineTextColor = this.getIntensityHeadlineTextColor.bind(this);
    }


    handleItemValueChange(event) {
        var value = event.target.value;
        var itemValue = {
            value: null,
            situation: null,
            intensity: null
        };
        if (value === "1" || value === "2") {
            itemValue = {
                value: value,
                situation: this.state.itemValue.situation,
                intensity: this.state.itemValue.intensity
            };
        } else {
            itemValue = {
                value: value,
                situation: null,
                intensity: null
            };
        }
        this.props.handleItemValueChange(itemValue);
        this.setState({
            itemValue: itemValue
        });
    }

    handleItemValueSituationChange(event) {
        var value = event.target.value;
        var itemValue = {
            value: this.state.itemValue.value,
            situation: value,
            intensity: this.state.itemValue.intensity
        };
        this.props.handleItemValueChange(itemValue);
        this.setState({
            itemValue: itemValue
        });
    }

    handleItemValueIntensityChange(event) {
        var value = event.target.value;
        var itemValue = {
            value: this.state.itemValue.value,
            situation: this.state.itemValue.situation,
            intensity: value
        };
        this.props.handleItemValueChange(itemValue);
        this.setState({
            itemValue: itemValue
        });
    }

    handleNext() {
        if (this.state.itemValue.value) {
            this.props.handleNext();
        } else {
            this.setState({ openSkipDialog: true });
        }
    }

    handleItemSkip() {
        this.props.handleSkippedItem(this.props.item);
        this.props.handleNext();
    }

    handleSkipDialogClose() {
        this.setState({ openSkipDialog: false });
    }
    handleInterimResultClose() {
        this.setState({ openInterimResultDialog: false });
    }

    showInterimResult() {
        this.setState({ openInterimResultDialog: true });
    }

    checkDisableSituation() {
        if (this.state.itemValue.value === "3" || this.props.questionaireType === "2") {
            return true;
        } else {
            return false;
        }
    }

    checkDisableIntensity() {
        if (this.state.itemValue.value === "3") {
            return true;
        } else {
            return false;
        }
    }

    getSituationHeadlineTextColor() {
        if (this.checkDisableSituation()) {
            return "textSecondary";
        } else {
            return "textPrimary";
        }
    }

    getIntensityHeadlineTextColor() {
        if (this.checkDisableIntensity()) {
            return "textSecondary";
        } else {
            return "textPrimary";
        }
    }


    render() {
        const itemIdentifier = this.props.item.itemCategory.categoryAbbrv + "-" + this.props.item.itemCategory.itemCategoryId;
        const { classes } = this.props;
        const workflowButtons = (<div><ButtonGroup color="primary"><Button disabled={this.props.disableBack} onClick={this.props.handleBack}>Vorheriges Item</Button>
            <Button disabled={this.props.disableNext} onClick={this.handleNext}>Nächstes Item</Button>
            <Button onClick={this.showInterimResult}>Zwischenergebnis anzeigen</Button>
        </ButtonGroup>
            {/* <Button disabled={this.props.skippedItems && ((this.props.skippedItems.length > 0) ? false : true)} style={{ marginLeft: '40px' }} variant="contained" color="primary" onClick={this.props.navigateToSkippedItemsList}>Übersprungene Items ansehen</Button> */}
            <Button style={{ marginLeft: '40px' }} variant="contained" color="primary" disabled={this.props.disableFinish} onClick={this.props.handleFinish}>Einschätzung abschließen</Button>
        </div>);

        return (
            <Card>
                <SupplementscaleInterimResultDialog items={this.props.items} questionaireType={this.props.questionaireType} handleClose={this.handleInterimResultClose} open={this.state.openInterimResultDialog} />
                <SkipItemDialog itemIdentifier={itemIdentifier} handleClose={this.handleSkipDialogClose} open={this.state.openSkipDialog} handleItemSkip={this.handleItemSkip} />
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {itemIdentifier}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {this.props.item.itemText}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Kommentar: {this.props.item.itemComment}
                    </Typography>
                    <Divider variant="middle" className={classes.divider} />

                    <Typography variant="body1" gutterBottom>
                        Häufigkeit, mit der das Verhalten auftritt:
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup name="supplementscaleItem" value={this.state.itemValue.value} onChange={this.handleItemValueChange}>
                            <FormControlLabel style={{ marginBottom: '-1em' }} value="1" control={<Radio />} label="1 - stimmt häufig/oft" />
                            <FormControlLabel style={{ marginBottom: '-1em' }} value="2" control={<Radio />} label="2 - stimmt manchmal/selten" />
                            <FormControlLabel value="3" control={<Radio />} label="3 - Stimmt nie - Das Merkmal trifft so nie auf/ist nie zu beobachten" />
                        </RadioGroup>
                        <Typography style={{ marginTop: '+0.5em' }} variant="body1" color={this.getSituationHeadlineTextColor()} gutterBottom>
                            Situation, in der das Verhalten auftritt:
                        </Typography>
                        <RadioGroup name="supplementscaleItemSituation" value={this.state.itemValue.situation} onChange={this.handleItemValueSituationChange} >
                            <FormControlLabel style={{ marginBottom: '-1em' }} value="b" control={<Radio />} label="b - in durchschnittlichen Situationen / bei durchschnittlicher emotionaler Verfassung" disabled={this.checkDisableSituation()} />
                            <FormControlLabel value="c" control={<Radio />} label="c - Stimmt nur in schwierigen Situationen / bei schlechter emotionaler Verfassung" disabled={this.checkDisableSituation()} />
                        </RadioGroup>

                        <Typography style={{ marginTop: '+0.5em' }} variant="body1" color={this.getIntensityHeadlineTextColor()} gutterBottom>
                            Intensität, mit der das Verhalten auftritt:
                        </Typography>
                        <RadioGroup name="supplementscaleItemIntensity" value={this.state.itemValue.intensity} onChange={this.handleItemValueIntensityChange}>
                            <FormControlLabel style={{ marginBottom: '-1em' }} value="+" control={<Radio />} label="+ - hoch" disabled={this.checkDisableIntensity()} />
                            <FormControlLabel value="-" control={<Radio />} label="- - niedrig" disabled={this.checkDisableIntensity()} />
                        </RadioGroup>
                    </FormControl>
                </CardContent>
                <CardActions>
                    {this.props.workflow && workflowButtons}
                </CardActions>
            </Card>
        );
    }
}

export default withStyles(useStyles)(SupplementscaleItem);