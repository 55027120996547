import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, ListItemAvatar } from '@material-ui/core';
import AssessSkippedItemDialog from './AssessSkippedItemDialog';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    }
}));


export default function SkippedItemsList(props) {
    const classes = useStyles();
    var items = props.items;

    const [item, setItem] = useState(items[0]);
    const [openDialog, setOpenDialog] = useState(false);

    const handleClose = () => {
        setOpenDialog(false);
    }

    const handleClick = (event, item) => {
        setItem(item);
        setOpenDialog(true);
    }

    return (
        <div className={classes.root}>
            <AssessSkippedItemDialog handleSkippedItemValueChange={props.handleSkippedItemValueChange} item={item} handleClose={handleClose} open={openDialog} />
            <List component="nav">
                {items.map((item) =>
                    <ListItem button key={item.itemId} onClick={(event) => handleClick(event, item)} >
                        <ListItemAvatar>
                            <Avatar>{item.itemCategory.categoryAbbrv}</Avatar>
                        </ListItemAvatar>
                        <ListItemText className={classes.cutItemText} primary={item.itemCategory.categoryAbbrv + "-" + item.itemCategory.itemCategoryId} secondary={item.itemText} />
                    </ListItem>
                )}
            </List>
        </div>
    );
}