import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';



export default class RegisterFormCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            firstname: "",
            lastname: "",
            organization: "",
            email: "",
            street: "",
            postcode: "",
            city: "",
            'firstname-error': { error: false, text: "" },
            'lastname-error': { error: false, text: "" },
            'organization-error': { error: false, text: "" },
            'email-error': { error: false, text: "" },
            'street-error': { error: false, text: "" },
            'postcode-error': { error: false, text: "" },
            'city-error': { error: false, text: "" },
            successfulApiCall: false,
            errorApiCall: false
        }
    }

    render() {

        const _handleTextFieldChange = (e) => {
            this.setState({
                [e.target.id]: e.target.value
            });
        }

        const _handleSubmit = () => {
            var error = false;
            var newState = {};

            if (this.state['firstname'].length === 0) {
                error = true;
                newState['firstname-error'] = { error: true, text: "Bitte geben Sie einen Vornamen ein." };
            } else {
                newState['firstname-error'] = { error: false, text: "" };
            }
            if (this.state['lastname'].length === 0) {
                error = true;
                newState['lastname-error'] = { error: true, text: "Bitte geben Sie einen Nachnamen ein." };
            } else {
                newState['lastname-error'] = { error: false, text: "" };
            }
            if (this.state['email'].length === 0) {
                error = true;
                newState['email-error'] = { error: true, text: "Bitte geben Sie eine E-Mail Adresse ein." };
            } else {
                newState['email-error'] = { error: false, text: "" };
            }
            if (this.state['street'].length === 0) {
                error = true;
                newState['street-error'] = { error: true, text: "Bitte geben Sie eine Straße ein." };
            } else {
                newState['street-error'] = { error: false, text: "" };
            }
            if (this.state['postcode'].length === 0) {
                error = true;
                newState['postcode-error'] = { error: true, text: "Bitte geben Sie eine Postleitzahl ein." };
            } else {
                newState['postcode-error'] = { error: false, text: "" };
            }
            if (this.state['city'].length === 0) {
                error = true;
                newState['city-error'] = { error: true, text: "Bitte geben Sie eine Stadt ein." };
            } else {
                newState['city-error'] = { error: false, text: "" };
            }


            this.setState(newState);

            if (!error) {
                submitData(this);
            }
        };

        const submitData = (self) => {
            fetch('/api/register', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "firstname": this.state['firstname'],
                    "lastname": this.state['lastname'],
                    "email": this.state['email'],
                    "organization": this.state['organization'],
                    "street": this.state['street'],
                    "postcode": this.state['postcode'],
                    "city": this.state['city']
                })
            }).then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }).then(function (jsonBody) {
                self.setState({ errorApiCall: false, successfulApiCall: true });
            }).catch(function (error) {
                console.log(error);
                self.setState({ errorApiCall: true });
            });

        }


        if (!this.state.successfulApiCall) {
            return (
                <Card>
                    <CardContent>
                        {this.state.errorApiCall ? (
                            <Alert severity="error">Leider ist beim Abschicken ein technischer Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren den Support.</Alert>
                        ) : null}
                        <Typography variant="h5" gutterBottom>
                            Rechnungsdaten
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Persönliche Daten
                        </Typography>
                        <Typography paragraph>
                            <TextField required id="firstname" label="Vorname" onChange={_handleTextFieldChange} helperText={this.state['firstname-error'].text} error={this.state['firstname-error'].error} />
                            <TextField required id="lastname" label="Nachname" onChange={_handleTextFieldChange} helperText={this.state['lastname-error'].text} error={this.state['lastname-error'].error} />
                        </Typography>
                        <Typography paragraph>
                            <TextField required id="email" label="E-Mail" onChange={_handleTextFieldChange} helperText={this.state['email-error'].text} error={this.state['email-error'].error} />
                        </Typography>
                        <Typography variant="h1" gutterBottom />
                        <Typography variant="h6" gutterBottom>
                            Rechnungsadresse
                        </Typography>
                        <Typography paragraph>
                            <TextField id="organization" label="Organisation" onChange={_handleTextFieldChange} helperText={this.state['organization-error'].text} error={this.state['organization-error'].error} />
                        </Typography>
                        <Typography>
                            <TextField required id="street" label="Straße" onChange={_handleTextFieldChange} helperText={this.state['street-error'].text} error={this.state['street-error'].error} />
                        </Typography>
                        <Typography paragraph>
                            <TextField required id="postcode" label="PLZ" onChange={_handleTextFieldChange} helperText={this.state['postcode-error'].text} error={this.state['postcode-error'].error} />
                            <TextField required id="city" label="Stadt" onChange={_handleTextFieldChange} helperText={this.state['city-error'].text} error={this.state['city-error'].error} />
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" onClick={_handleSubmit}>Daten abschicken</Button>
                    </CardActions>
                </Card >

            );
        } else {
            return (
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Daten erfolgreich abgeschickt
                        </Typography>
                        <Typography paragraph>
                            Ihre Daten wurden erfolgreich verschickt. Sie erhalten in Kürze eine E-Mail mit einem Bestätigungslink. Bitte öffnen Sie diesen Link in demselben Browser um fortzufahren.
                        </Typography>
                    </CardContent>
                </Card >
            );
        }
    }
}