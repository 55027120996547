import React from 'react';
import Grid from '@material-ui/core/Grid';
import AssessmentTypeCard from './AssessmentTypeCard.js';

export default function AssessmentTypesGrid(props) {
    return (
        <Grid container spacing={3}>
            <Grid item>
                <AssessmentTypeCard link='mainscale?new' name='Hauptskala' text='erfassen Sie eine neue Einschätzung zur Hauptskala.' />
            </Grid>
            <Grid item>
                <AssessmentTypeCard link='supplementscale?new' name='Ergänzungsskala' text='erfassen Sie eine neue Einschätzung zur Ergänzungsskala.' />
            </Grid>
            <Grid item>
                <AssessmentTypeCard link='main+supplement' name='Haupt- und Ergänzungsskala' text='erfassen Sie eine neue Einschätzung zur Haupt- und Ergänzungsskala.' />
            </Grid>
        </Grid >
    );
}