import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from "@material-ui/core";

export default class PersonCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }



    render() {

        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {this.props.patient.firstname} {this.props.patient.lastname} (geboren am {this.props.patient.birthday.toLocaleDateString("de-DE")})
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        eingeschätzt von {this.props.assessor.firstname} {this.props.assessor.lastname}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}